/* eslint-disable react/no-danger */
import React from 'react';
import { graphql, Link } from 'gatsby';
import Disqus from 'disqus-react';
import Title from 'components/common/Title';
import { AppContextProvider } from 'contexts/AppContext';
import PageLayout from 'components/layout/PageLayout';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Hidden } from 'components/Grid';
import { useI18n, I18nProvider } from 'i18n';
import FacebookIcon from 'assets/icons/facebook.svg';
import InstagramIcon from 'assets/icons/instagram.svg';
import TikTokIcon from 'assets/icons/tiktok.svg';
import RehypeReact from 'rehype-react';
import Button from 'components/common/Button';
import { appHomeUrl } from 'config/settings';
import routePaths from 'constants/route-paths';
import get from 'lodash/get';
import s from './BlogLayout.module.scss';

const disqusShortname = 'meinbau';

const Blog = ({ post, disqusConfig, title, instagramUrl, facebookUrl, tikTokUrl }) => {
  const { t, locale } = useI18n();

  const renderAst = new RehypeReact({
    createElement: React.createElement,
    components: {
      card: ({ children }) => {
        return <div className={s.card}>{children}</div>;
      },
      'content-center': ({ children }) => {
        return <div className={s.contentCenter}>{children}</div>;
      },
      'flex-container': ({ children }) => {
        return <div className={s.flexContainer}>{children}</div>;
      },
      'flex-item': ({ children }) => {
        return <div className={s.flexItem}>{children}</div>;
      },
      'flex-item-first-on-mobile': ({ children }) => {
        return <div className={s.flexItemFirstOnMobile}>{children}</div>;
      },
      'featured-image': ({ children }) => {
        return <div className={s.featuredImageWrapper}>{children}</div>;
      },
      'heading-level-two': ({ children }) => <h2 className={s.specialHeadingLevelTwo}>{children}</h2>,
      'cta-button': ({ children }) => {
        const ctaButtonLink = get(children[0], 'props.children[0].props.href');
        const href = ctaButtonLink || appHomeUrl;
        return (
          <div className={s.ctaBtnWrapper}>
            <a href={href} target="_blank" rel="noreferrer">
              <Button className={s.ctaBtn} variant="danger">
                {children}
              </Button>
            </a>
          </div>
        );
      },
      list: ({ children, ...rest }) => (
        <div className={s.listContainer} {...rest}>
          {children}
        </div>
      ),
    },
  }).Compiler;

  return (
    <>
      <Hidden xs sm md>
        <Col lg={1}>
          <Link to={routePaths[locale].blog}>
            <div className={s.imgBox}>
              <ArrowBack className={s.arrowBack} />
            </div>
          </Link>
        </Col>
      </Hidden>
      <Col lg={11}>
        <div className={s.container}>
          <Title className={s.title}>{title}</Title>
          <div className={s.headWrapper}>
            <span className={s.date}>
              {post.frontmatter.date} · {post.fields.readingTime.text}
            </span>
            <div className={s.socialContainer}>
              <span className={s.divide}>{t('blog.divide')}</span>
              <div className={s.wrapper}>
                <a className={s.social} href={facebookUrl} target="_blank" rel="noreferrer">
                  <img src={FacebookIcon} alt={t('footer.facebook_logo')} />
                  <span>{t('blog.facebook')}</span>
                </a>
                <a className={s.social} href={instagramUrl} target="_blank" rel="noreferrer">
                  <img src={InstagramIcon} alt={t('footer.instagram_logo')} />
                  <span>{t('blog.instagram')}</span>
                </a>
                <a className={s.social} href={tikTokUrl} target="_blank" rel="noreferrer">
                  <img src={TikTokIcon} alt={t('footer.tik_tok_logo')} />
                  <span>{t('blog.tiktok')}</span>
                </a>
              </div>
            </div>
          </div>
          <div className={s.content}>{renderAst(post.htmlAst)}</div>
          <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        </div>
      </Col>
    </>
  );
};

const Template = ({ data, location }) => {
  const post = data.markdownRemark;
  const { title, metaTitle, metaDescription, featuredImage, ...rest } = post.frontmatter;

  const disqusConfig = {
    url: `${data.site.siteMetadata.siteUrl}${location.pathname}`,
    identifier: post.fields.slug,
    title,
  };

  return (
    <AppContextProvider>
      <I18nProvider>
        <PageLayout {...{ metaTitle, metaDescription, featuredImage }}>
          <Grid>
            <Row>
              <Blog {...{ title, post, disqusConfig, ...rest }} />
            </Row>
          </Grid>
        </PageLayout>
      </I18nProvider>
    </AppContextProvider>
  );
};

export default Template;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      fields {
        slug
        readingTime {
          text
        }
      }
      htmlAst
      frontmatter {
        title
        metaTitle
        date(formatString: "MMMM DD, YYYY")
        metaDescription
        author
        instagramUrl
        facebookUrl
        tikTokUrl
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 660) {
              ...GatsbyImageSharpSizes
            }
            resize(width: 800) {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`;
